import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import axios from "axios";
import PocketBase from "pocketbase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const [spinner, setSpinner] = useState(true);

  const [companyInfo, setCompanyInfo] = useState([]);
  const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
  const backend_url = process.env.REACT_APP_BACKEND_URL;
  const culture_api_url = process.env.REACT_APP_CULTURE_API_URL;
  const [formData, setFormData] = useState({
    form_info: {},
  });
  const [onboardingData, setOnboardingData] = useState([]);
  const [globalEmail, setGlobalEmail] = useState(null);
  const [globalAccessToken, setGlobalAccessToken] = useState(null);
  // const navigate = useNavigate();
  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      form_info: {
        ...prevData.form_info,
        [key]: value,
      },
    }));
  };

  const updateFiles = (type, files) => {
    setFormData((prevData) => ({
      ...prevData,
      form_info: {
        ...prevData?.form_info,
        [type]: [...(prevData?.form_info?.[type] || []), ...files],
      },
    }));
  };

  const removeFile = (type, index) => {
    setFormData((prevData) => ({
      ...prevData,
      form_info: {
        ...prevData?.form_info,
        [type]: prevData?.form_info?.[type]?.filter((_, i) => i !== index),
      },
    }));
  };

  const submitFormData = async () => {
    setSpinner(true);
    try {
      const formDataToSend = new FormData();

      // Append user_profile as a JSON string
      formDataToSend.append("user_profile", JSON.stringify(formData.form_info));

      // Append user_resume (assuming only one resume is allowed)
      if (formData.form_info.resume && formData.form_info.resume.length > 0) {
        formDataToSend.append("user_resume", formData.form_info.resume[0]);
      } else {
        throw new Error("Resume is required");
      }

      // Append job_descriptions
      if (formData.form_info.job_description) {
        formData.form_info.job_description.forEach((file) => {
          formDataToSend.append("job_descriptions", file);
        });
      }

      const response = await axios.post(
        `${backend_url}/api/onboarding`,
        formDataToSend,
        {
          headers: {
            Email: globalEmail,
            "Access-token": globalAccessToken,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to submit onboarding data");
      }
      const result = response.data;

      // Process company info and other data as before
      const companies = result.result.map((item) => item.company);
      const companyInfoPromises = companies.map(async (companyName) => {
        const thirdApiResponse = await axios.post(
          `${culture_api_url}/culture_analysis`,
          null,
          {
            params: {
              company: JSON.stringify([companyName]),
              llm_provider: "openai",
              review_count: "10",
              user_id: "",
              stripe_product_id: "",
            },
          }
        );

        if (thirdApiResponse.status !== 200) {
          throw new Error(
            `Failed to retrieve company information for ${companyName}`
          );
        }

        return thirdApiResponse.data;
      });

      const allCompanyInfo = await Promise.all(companyInfoPromises);
      setCompanyInfo(allCompanyInfo.flat());

      // Save job match and company info data using the new API
      const resultsData = {
        collection_name: "results",
        data: {
          job_match: result.result,
          company_info_dei: allCompanyInfo.flat(),
        },
      };
      const updateResponse = await axios.patch(
        `${backend_url}/api/insert_or_update_data`,
        resultsData,
        {
          headers: {
            Email: globalEmail,
            "Access-token": globalAccessToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (updateResponse.status !== 200) {
        throw new Error("Failed to update results data");
      }

      await fetchAndSetUserData();
      setSpinner(false);
      return { result };
    } catch (error) {
      console.error("Error submitting form data:", error);
      setSpinner(false);
      throw error;
    }
  };

  const authenticateUser = async (email, password) => {
    try {
      const authData = await pb
        .collection("users")
        .authWithPassword(email, password);
      if (pb.authStore.isValid) {
        setUser(authData.record);
        localStorage.setItem("email", email);
        localStorage.setItem("AccessToken", authData.token);
        setGlobalEmail(email);
        setGlobalAccessToken(authData.token);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("AccessToken");
    setGlobalEmail("");
    setGlobalAccessToken("");
  };

  const createRecord = async (collectionName, recordData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/create-record`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Email: globalEmail,
            "Access-token": globalAccessToken,
          },
          body: JSON.stringify({
            email: globalEmail,
            token: globalAccessToken,
            collection_name: collectionName,
            record_data: recordData,
          }),
        }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  const fetchAndSetUserData = useCallback(async () => {
    if (globalEmail && globalAccessToken) {
      setSpinner(true);
      try {
        const response = await axios.get(
          `${backend_url}/api/get_all_collection_data`,
          {
            headers: {
              Email: globalEmail,
              "Access-token": globalAccessToken,
            },
          }
        );
        setIsOnboardingComplete(response.data.success);
        if (response.data) {
          const data = response.data.data;
          setOnboardingData(data);

          // Prepare form_info object
          const form_info = Object.entries(data).reduce((acc, [key, value]) => {
            if (value.items && value.items[0]) {
              return { ...acc, ...value.items[0] };
            }
            return acc;
          }, {});

          // Fetch resume and job description files
          form_info.resume = await Promise.all(
            form_info.resume?.map((filename) => fetchFile(filename)) || []
          );
          form_info.job_description = await Promise.all(
            form_info.job_description?.map((filename) => fetchFile(filename)) ||
              []
          );

          // Update formData
          setFormData((prevData) => ({
            ...prevData,
            form_info,
          }));

          setSpinner(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setSpinner(false);
      }
    }
  }, [globalEmail, globalAccessToken]);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedAccessToken = localStorage.getItem("AccessToken");
    if (storedEmail && storedAccessToken) {
      setGlobalEmail(storedEmail);
      setGlobalAccessToken(storedAccessToken);
    }
  }, []);

  useEffect(() => {
    if (globalEmail && globalAccessToken) {
      fetchAndSetUserData();
    }
  }, [globalEmail, globalAccessToken]);

  // Helper function to fetch individual files
  const fetchFile = async (filename) => {
    try {
      const response = await axios.get(`${backend_url}/api/get_file`, {
        headers: {
          Email: globalEmail,
          "Access-token": globalAccessToken,
        },
        params: { filename },
        responseType: "blob",
      });
      return new File([response.data], filename, { type: response.data.type });
    } catch (error) {
      console.error(`Error fetching file ${filename}:`, error);
      return null;
    }
  };

  const fileManagementUpload = async (files, category) => {
    try {
      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));
      formData.append("category", category);

      const uploadResponse = await axios.post(
        `${backend_url}/api/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Email: globalEmail,
            "Access-token": globalAccessToken,
          },
        }
      );

      if (!uploadResponse.data.success) {
        throw new Error("Failed to upload files");
      }

      await fetchAndSetUserData();
      return uploadResponse.data;
    } catch (error) {
      setSpinner(false);
      throw error;
    }
  };

  const handleDeleteFile = async (files) => {
    setSpinner(true);
    try {
      const response = await axios.delete(`${backend_url}/api/delete-files`, {
        data: files,
        headers: {
          "Content-Type": "application/json",
          Email: globalEmail,
          "Access-token": globalAccessToken,
        },
      });
      await fetchAndSetUserData();
      return response;
    } catch (error) {
      throw error;
    } finally {
      setSpinner(false);
    }
  };

  const handleDownloadFile = async (file_url) => {
    setSpinner(true);
    window.open(file_url, "_blank");
    setSpinner(false);
  };

  const downloadCultureAnalysisPDF = async (content) => {
    setSpinner(true);
    try {
      const response = await axios.post(
        "https://culturedev.hrbrain.ai/doc_exporter",
        {
          type: "pdf",
          analysis: "Culture Analysis",
          content: content,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "culture_analysis.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error("Failed to download PDF. Please try again.");
    } finally {
      setSpinner(false);
    }
  };

  return (
    <DataContext.Provider
      value={{
        formData,
        updateFormData,
        updateFiles,
        submitFormData,
        removeFile,
        authenticateUser,
        user,
        handleLogout,
        fetchAndSetUserData,
        companyInfo,
        isOnboardingComplete,
        spinner,
        setSpinner,
        createRecord,
        fileManagementUpload,
        handleDeleteFile,
        handleDownloadFile,
        setSpinner,
        globalEmail,
        globalAccessToken,
        onboardingData,
        setIsOnboardingComplete,
        setGlobalEmail,
        setGlobalAccessToken,
        downloadCultureAnalysisPDF,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
