import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useData } from "../../contexts/DataContext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { toast } from "react-toastify";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#A020F0",
  color: "#fff",
  width: "150px",
  height: "50px",
  fontSize: "16px",
  marginTop: "20px",
  "&:hover": {
    backgroundColor: "navy",
  },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  borderColor: "#fff",
  width: "150px",
  height: "50px",
  fontSize: "16px",
  marginTop: "20px",
  "&:hover": {
    borderColor: "#A020F0",
    color: "#A020F0",
  },
}));

const ConfirmationIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "#A020F0",
  width: "120px",
  height: "120px",
  "& .MuiSvgIcon-root": {
    fontSize: "120px",
  },
}));

export default function Confirmation({ handleOpen }) {
  const { submitFormData, spinner, setSpinner } = useData();
  const navigate = useNavigate();

 

  const handleConfirm = async () => {
    const infoToastId = toast.info("Submitting form data...", {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
    });
    setSpinner(true);
    try {
      await submitFormData();
      toast.dismiss(infoToastId);
      toast.success("Form submitted successfully!", {
        onClose: () => {
          navigate("/job-match");
        },
      });
      setSpinner(false);
    } catch (error) {
      toast.dismiss(infoToastId);
      toast.error("Error submitting form");
      setSpinner(false);
    }
  };


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: 4,
        color: "#ffffff",
      }}
    >
      <ConfirmationIcon>
        <CheckCircleOutlineIcon />
      </ConfirmationIcon>
      <Typography component="h1" variant="h4" sx={{ m: 4, fontWeight: "bold" }}>
        Please Confirm Your Details
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 4, color: "rgba(255, 255, 255, 0.7)" }}
      >
        Before submitting, please review and confirm that all the information
        you've provided is correct.
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <StyledButton variant="contained" onClick={handleConfirm}>
          Confirm
        </StyledButton>
        {/* <OutlinedButton variant="outlined" onClick={handleBackToHome}>
          Home
        </OutlinedButton> */}
      </Box>
    </Box>
  );
}
