import React, { useEffect, useState } from "react";
import Vapi from "@vapi-ai/web";
import { isPublicKeyMissingError } from "../utils";
import {
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  Button,
  Typography,
  Container,
} from "@mui/material";
import "dotenv/config";
import LiveTranscript from "../components/call/LiveTranscript";
import "../styles/BotPage.css";
import jsPDF from "jspdf";
import LeftMenuLayout from "../components/Layout/LeftMenuLayout";
import { useData } from "../contexts/DataContext";
import SuggestionCard from "../components/SuggestionCard";
import { useLocation } from "react-router-dom";
import LikeToWork from "../components/LikeToWork/LikeToWork";
import LoadingSpinner from "../components/LoadingSpinner";
import MicIcon from "@mui/icons-material/Mic";
import VoiceAnimation from "../components/call/AudioSpectrum";
import { keyframes } from "@mui/system";
import BotBgAnimation from "../components/Animation/BotBgAnimation";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast, ToastContainer } from "react-toastify";

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

const vapi = new Vapi(PUBLIC_KEY);

const BotPage = () => {
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] =
    useState(false);
  const [conversation, setConversation] = useState([]);
  const [isMuted, setIsMuted] = useState(vapi.isMuted());
  const [hasTranscript, setHasTranscript] = useState(false);
  const [assistantID, setAssistantID] = useState();
  const location = useLocation();
  const [uploadedFile, setUploadedFile] = useState(null);

  const {
    companyInfo,
    formData,
    createRecord,
    fileManagementUpload,
    setSpinner,
    spinner,
    onboardingData,
    fetchAndSetUserData,
  } = useData();

  const assistant_variables = onboardingData?.assistant_variables?.items?.[0];
  const REACT_APP_INTERVIEW_ASSISTANT_ID =
    process.env.REACT_APP_INTERVIEW_ASSISTANT_ID;
  const REACT_APP_COACH_ASSISTANT_ID = process.env.REACT_APP_COACH_ASSISTANT_ID;
  const REACT_APP_NEXT_STEP_BLUEPRINT_ASSISTANT_ID =
    process.env.REACT_APP_NEXT_STEP_BLUEPRINT_ASSISTANT_ID;

  const careerAssistanceSuggestions = {
    "Career Transition (Finding a New Job)": [
      "I'm targeting a new role or career change",
      "I need advice on networking and personal branding (resume, LinkedIn)",
      "I'm conducting market research around industries and job markets that may be a good fit for me",
      "I am applying for a job and would like to understand what I should be focussing on",
    ],
    "Skill Development (Upskilling or Reskilling)": [
      "I'm identifying skill gaps and future skill needs",
      "Help me in creating a learning and development plan",
      "Help me with seeking certifications, education, or training",
      "I want to leverage my current role for skill-building opportunities",
    ],
    "Onboarding in a New Role (First 30/60/90 Days)": [
      "Help me in defining key success factors and quick wins",
      "Help me Building internal relationships and networking",
      "Help me in setting goals and performance expectations with leadership",
      "How do I navigate organizational culture and politics at my current job?",
    ],
    "Career Progression in Current Role": [
      "How should I plan for promotions or leadership roles",
      "How can I maximize impact and visibility in the organization",
      "Help me with navigating performance reviews and feedback",
      "Help me in managing work-life balance and personal development",
    ],
    "Career Direction (Uncertainty About Next Steps)": [
      "Help me with clarifying career goals and personal values",
      "How do I explore new career paths or pivots",
      "Help me with identifying long-term aspirations and plans",
      "How do I overcoming obstacles and create actionable steps",
    ],
    "Personal Leadership Development": [
      "How do I building executive presence and influence?",
      "How can I enhance emotional intelligence and interpersonal skills",
      "How can I develop decision-making and problem-solving abilities",
      "How can I manage stress and build resilience",
    ],
  };

  const InterviewAssistantSuggestions = {
    "Interview Preparation": [
      "Can you assist me with behavioral interview questions?",
      "Could you conduct an interview based on my previous job experience?",
      "Can you prepare me for the HR round for this specific job role?",
      "Can you simulate an interview focused on my last job position?",
    ],
  };

  const NextStepBlueprintSuggestions = {
    "Career Planning": [
      "Can you help me with my resume?",
      "How should I prepare for an upcoming interview?",
      "I'm thinking of changing careers. Where do I start?",
      "What are the next steps I should take in my career?",
    ],
  };

  const blinkAnimation = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  `;

  useEffect(() => {
    setAssistantID(
      location?.pathname === "/interview-assistant-voice-bot"
        ? REACT_APP_INTERVIEW_ASSISTANT_ID
        : location?.pathname === "/next-step-blueprint-voice-bot"
        ? REACT_APP_NEXT_STEP_BLUEPRINT_ASSISTANT_ID
        : REACT_APP_COACH_ASSISTANT_ID
    );
  }, [location]);

  useEffect(() => {
    const handleMuteChange = () => setIsMuted(vapi.isMuted());

    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);
      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);
      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("speech-start", () => setAssistantIsSpeaking(true));
    vapi.on("speech-end", () => setAssistantIsSpeaking(false));
    vapi.on("volume-level", (level) => setVolumeLevel(level));

    vapi.on("error", (error) => {
      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

    vapi.on("message", (message) => {
      if (message.type === "conversation-update" && message.conversation) {
        if (message.conversation.length > 0) {
          message.conversation = message.conversation.filter(
            (msg) => msg.role !== "system"
          );
        }
        setConversation(message.conversation);
        setHasTranscript(message.conversation.length > 0);
      }
    });

    vapi.on("mute-status-change", handleMuteChange);

    return () => {
      vapi.off("mute-status-change", handleMuteChange);
    };
  }, []);

  let assistantOverrides = {};
  const startCallInline = () => {
    setConnecting(true);
    const interviewAssistantFileIds = assistant_variables?.files?.filter(file => file?.category !== "aspirant_profile")?.map((file) => file?.file_id);
    if (assistantID === REACT_APP_INTERVIEW_ASSISTANT_ID) {
      assistantOverrides = {
        variableValues: {
          name: assistant_variables?.name,
          job_details: assistant_variables?.job_details,
        },
        model: {
          provider: "openai",
          model: "gpt-4o",
          knowledgeBase: {
            provider: "canonical",
            fileIds: interviewAssistantFileIds,
          },
        },
      };

      vapi
        .start(assistantID, assistantOverrides)
        ?.then((call) => {
          const call_id = call?.id;
          // Create a record with call_id and assistant_id
          createRecord("calls", {
            call_id: call_id,
            assistant_id: assistantID,
          })
            ?.then((data) => {})
            ?.catch((error) => {
              toast.error("Failed to start call. Please try again.");
            });
        })
        ?.catch((error) => {
          setConnecting(false);
          toast.error("Failed to start call. Please try again.");
        });
    } else if (assistantID === REACT_APP_COACH_ASSISTANT_ID) {
      const coachAssistantFileIds = assistant_variables?.files?.map((file) => file?.file_id);
      assistantOverrides = {
        variableValues: {
          name: assistant_variables?.name,
          user_profile_text: assistant_variables?.user_profile_text,
          match_scores: assistant_variables?.match_scores,
        },
        model: {
          provider: "openai",
          model: "gpt-4o",
          knowledgeBase: {
            provider: "canonical",
            fileIds: coachAssistantFileIds,
          },
        },
      };
      vapi
        .start(assistantID, assistantOverrides)
        ?.then((call) => {
          const call_id = call?.id;
          // Create a record with call_id and assistant_id
          createRecord("calls", {
            call_id: call_id,
            assistant_id: assistantID,
          })
            ?.then((data) => {})
            ?.catch((error) => {
              toast.error("Failed to start call. Please try again.");
            });
        })
        ?.catch((error) => {
          setConnecting(false);
          toast.error("Failed to start call. Please try again.");
        });
    } else if (assistantID === REACT_APP_NEXT_STEP_BLUEPRINT_ASSISTANT_ID) {
      const aspirantProfileFiles = assistant_variables?.files?.filter(file => file?.category === "aspirant_profile") || [];
      
      const fileNames = aspirantProfileFiles.map(file => file?.file_name);
      const aspirantProfileFileIds = aspirantProfileFiles.map(file => file?.file_id);
      assistantOverrides = {
        variableValues: {
          name: assistant_variables?.name,
          aspirational_profile_filenames: fileNames,
        },
        model: {
          provider: "openai",
          model: "gpt-4o",
          knowledgeBase: {
            provider: "canonical",
            fileIds: aspirantProfileFileIds,
          },
        },
      };
      console.log("assistantOverrides", assistantOverrides);
      vapi
        .start(assistantID, assistantOverrides)
        ?.then((call) => {
          const call_id = call?.id;
          // Create a record with call_id and assistant_id
          createRecord("calls", {
            call_id: call_id,
            assistant_id: assistantID,
          })
            ?.then((data) => {})
            ?.catch((error) => {
              toast.error("Failed to start call. Please try again.");
            });
        })
        ?.catch((error) => {
          setConnecting(false);
          toast.error("Failed to start call. Please try again.");
        });
    }
  };
  const endCall = () => vapi.stop();

  const toggleMute = () => {
    vapi.setMuted(!isMuted);
    setIsMuted(!isMuted);
  };

  const handleSuggestionClick = (suggestion) => {
    console.log("Sending suggestion:", suggestion);
    if (connected) {
      vapi.send({
        type: "add-message",
        message: {
          role: "user",
          content: suggestion,
        },
      });
      toast.info("Suggestion added");
    } else {
      console.warn("Cannot send suggestion: Not connected to Vapi");
      toast.warning("Please start the call before sending suggestions.");
    }
  };

  const formatTranscript = (conversation) => {
    return conversation
      .map(
        (message) =>
          `${message.role.charAt(0).toUpperCase() + message.role.slice(1)}: ${
            message.content
          }`
      )
      .join("\n");
  };

  const downloadTranscript = () => {
    const transcriptData = formatTranscript(conversation);
    const doc = new jsPDF();

    // Page setup
    const margin = 10; // Margin from the edge
    const padding = 15; // Padding inside the border
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    // Header
    doc.setFontSize(18);
    doc.setFont("Arial", "bold");
    doc.text("Transcript", margin + padding, margin + padding + 10); // Header text

    // Border
    doc.rect(
      margin,
      margin,
      pageWidth - 2 * margin,
      pageHeight - 2 * margin,
      "S"
    );

    // Add the transcript data
    doc.setFontSize(10);
    doc.setFont("Arial", "normal");
    const contentWidth = pageWidth - 2 * margin - 2 * padding;
    const contentHeight = pageHeight - 2 * margin - 2 * padding;
    const lines = doc?.splitTextToSize(transcriptData, contentWidth);

    let y = margin + padding + 20; // Start after header and padding
    lines.forEach((line) => {
      if (y > pageHeight - margin - padding - 10) {
        // Check if new page is needed
        doc.addPage();
        y = margin + padding + 10;
        doc.text("Transcript", margin + padding, margin + padding + 10); // Re-add header on new page
        doc.rect(
          margin,
          margin,
          pageWidth - 2 * margin,
          pageHeight - 2 * margin,
          "S"
        ); // Re-add border
      }
      doc.text(line, margin + padding, y);
      y += 10;
    });

    // Add page numbers
    doc.setFontSize(8);
    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.text(
        `Page ${i} of ${totalPages}`,
        pageWidth - margin - padding - 20,
        pageHeight - margin - padding + 10
      );
    }

    // Save the PDF
    doc.save("transcript.pdf");
  };

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target?.files || []);
    if (files.length > 0) {
      const infoToastId = toast.info(`Uploading file...`, {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
      });
      setSpinner(true);
      try {
        const response = await fileManagementUpload?.(
          files,
          "aspirant_profile"
        );
        if (response?.success) {
          toast.dismiss(infoToastId);
          toast.success(`File uploaded successfully!`);
          setUploadedFile(files[0]); // Update the state with the first uploaded file
        } else {
          toast.dismiss(infoToastId);
          toast.error(`Failed to upload file.`);
        }
        await fetchAndSetUserData?.();
      } catch (error) {
        toast.dismiss(infoToastId);
        toast.error(`An error occurred while uploading file.`);
      } finally {
        setSpinner(false);
      }
    }
  };

  const getSuggestions = () => {
    if (location?.pathname === "/interview-assistant-voice-bot") {
      return InterviewAssistantSuggestions;
    } else if (location?.pathname === "/next-step-blueprint-voice-bot") {
      return NextStepBlueprintSuggestions;
    } else {
      return careerAssistanceSuggestions;
    }
  };

  return (
    <LeftMenuLayout>
      <BotBgAnimation />
      <Box id="voice-bot-main-container">
        <Box id="voice-bot-header">
          <Typography variant="h4" gutterBottom sx={{ color: "#ffffff" }}>
            {location?.pathname === "/interview-assistant-voice-bot"
              ? "Interview Assistant"
              : location?.pathname === "/next-step-blueprint-voice-bot"
              ? "Career Development Guidance"
              : "Career Coach Assistant"}
          </Typography>
        </Box>
        <Box
          id="voice-bot-container"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 3,
            padding: { xs: 2, md: 4 },
            margin: "0 auto",
            maxWidth: "1200px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              width: "100%",
              maxWidth: { xs: "100%", md: "400px" },
              margin: "0 auto",
            }}
          >
            <SuggestionCard
              onSuggestionClick={handleSuggestionClick}
              suggestions={getSuggestions()}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              maxWidth: { xs: "100%", md: "300px" },
              // marginTop: "50px",
              position: "relative",
              minHeight: "450px",
            }}
          >
            {!connected && (
              <Typography
                variant="body1"
                sx={{
                  color: "#ffffff",
                  // marginBottom: 2,
                  textAlign: "center",
                }}
              >
                To start, click the microphone
              </Typography>
            )}
            {/* Call button */}
            <Box
              className="call-button"
              onClick={connected ? null : startCallInline}
              sx={{
                width: "200px",
                height: "200px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: connected
                  ? "rgba(62, 240, 124, 0.2)"
                  : "rgba(0, 176, 255, 0.2)",
                cursor: connected ? "default" : "pointer",
                transition: "all 0.3s ease",
                margin: "0 auto",
                marginTop: "50px",
                "&:hover": {
                  backgroundColor: connected
                    ? "rgba(62, 240, 124, 0.2)"
                    : "rgba(0, 176, 255, 0.3)",
                },
                "& .MicIcon": {
                  animation: connecting
                    ? `${blinkAnimation} 1s infinite`
                    : "none",
                },
              }}
            >
              {connected ? (
                <VoiceAnimation isActive={true} />
              ) : (
                <MicIcon
                  className="MicIcon"
                  sx={{
                    fontSize: 80,
                    color: connecting ? "#FF4081" : "#00B0FF",
                  }}
                />
              )}
            </Box>

            {connected && (
              <Box
                sx={{
                  marginTop: "40px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  onClick={toggleMute}
                  sx={{
                    mb: 2,
                    width: "200px",
                    backgroundColor: isMuted
                      ? "rgba(255, 64, 129, 0.2)"
                      : "rgba(0, 176, 255, 0.2)",
                    color: isMuted ? "#FF4081" : "#00B0FF",
                    "&:hover": {
                      backgroundColor: isMuted
                        ? "rgba(255, 64, 129, 0.3)"
                        : "rgba(0, 176, 255, 0.3)",
                    },
                  }}
                >
                  {isMuted ? "Unmute Call" : "Mute Call"}
                </Button>
                <Button
                  variant="contained"
                  onClick={endCall}
                  sx={{
                    mb: 2,
                    width: "200px",
                    backgroundColor: "rgba(255, 64, 129, 0.2)",
                    color: "#FF4081",
                    "&:hover": {
                      backgroundColor: "rgba(255, 64, 129, 0.3)",
                    },
                  }}
                >
                  End Call
                </Button>
              </Box>
            )}
            {location?.pathname === "/next-step-blueprint-voice-bot" && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "300px",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: 1,
                    color: "#00B0FF",
                  }}
                >
                  Upload your aspirant's profile
                </Typography>
                <input
                  accept="application/pdf"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                      width: "100%",
                      height: "36px",
                      backgroundColor: "rgba(0, 176, 255, 0.2)",
                      color: "#00B0FF",
                      "&:hover": {
                        backgroundColor: "rgba(0, 176, 255, 0.3)",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {uploadedFile ? (
                      <Typography
                        component="span"
                        noWrap
                        sx={{
                          maxWidth: "240px",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        {uploadedFile.name}
                      </Typography>
                    ) : (
                      "Upload File"
                    )}
                  </Button>
                </label>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              flex: 1,
              width: "100%",
              maxWidth: { xs: "100%", md: "400px" },
            }}
          >
            {connected && (
              <LiveTranscript
                conversation={conversation}
                downloadTranscript={downloadTranscript}
                hasTranscript={hasTranscript}
                isConnected={connected}
              />
            )}
          </Box>
        </Box>
        {location?.pathname !== "/next-step-blueprint-voice-bot" && (
          <LikeToWork companyInfo={companyInfo} formData={formData} />
        )}
      </Box>
      <Snackbar
        open={showPublicKeyInvalidMessage}
        autoHideDuration={3000}
        onClose={() => setShowPublicKeyInvalidMessage(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Is your Vapi Public Key missing? (recheck your code)
        </Alert>
      </Snackbar>
      {spinner && <LoadingSpinner />}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </LeftMenuLayout>
  );
};

export default BotPage;