import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  Avatar,
} from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { useNavigate } from "react-router-dom";
import LeftMenuLayout from "../Layout/LeftMenuLayout";
import { useData } from "../../contexts/DataContext";
import BotBgAnimation from "../Animation/BotBgAnimation";
import LoadingSpinner from "../LoadingSpinner";

const OnBoardingHome = () => {
  const navigate = useNavigate();
  const { isOnboardingComplete, spinner } = useData();

  return (
    <LeftMenuLayout>
      <BotBgAnimation />
      <Container
        maxWidth="lg"
        sx={{ minHeight: "100vh", display: "flex", alignItems: "center" }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", md: "flex-start" },
                  mb: 4,
                }}
              >
                {/* <Avatar
                  sx={{ bgcolor: "#A020F0", width: 60, height: 60, mr: 2 }}
                >
                  <SmartToyIcon fontSize="large" />
                </Avatar> */}
                <Typography
                  variant="h4"
                  sx={{
                    color: "text.primary",
                    fontWeight: "bold",
                    background: "linear-gradient(45deg, #A020F0, #2126f9)",
                    backgroundClip: "text",
                    textFillColor: "transparent",
                  }}
                >
                  AI Career Assistant
                </Typography>
              </Box>
              <Typography
                variant="h2"
                component="h1"
                sx={{
                  fontWeight: 700,
                  background: "linear-gradient(45deg, #A020F0, #2196f3)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  mb: 3,
                }}
              >
                Your Personal AI Career Guide
              </Typography>
              <Typography variant="h5" sx={{ mb: 4, color: "rgba(255, 255, 255, 0.7)" }}>
                Supercharge your career with AI-driven job matching,
                personalized guidance, and growth strategies that get you where
                you want to be.
              </Typography>
              <Typography variant="body1" sx={{ mb: 4, color: "rgba(255, 255, 255, 0.8)" }}>
                Unlock the Full Power of AI for Your Career
              
                With our career support platform, you'll receive personalized coaching, smart job matching, and deep insights tailored to your needs. Whether you're seeking to land your dream job, grow within your company, or navigate a new professional path, our AI-driven tools guide you every step of the way. You'll practice for interviews, identify roles that align with your strengths, and gain career clarity. For businesses, our platform accelerates internal growth, reduces turnover, and unlocks the full potential of your talent through personalized development plans and leadership guidance.
              
                Start today and turn career uncertainty into opportunity.
              </Typography>
              <Button
                variant="contained"
                size="large"
                startIcon={<RocketLaunchIcon />}
                sx={{
                  fontWeight: "bold",
                  color: "common.white",
                  background: "linear-gradient(45deg, #A020F0, #2196f3)",
                  padding: "12px 24px",
                  borderRadius: "30px",
                  boxShadow:
                    "0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 0 rgba(0, 127, 255, 0)",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-3px)",
                    boxShadow:
                      "0 8px 25px 0 rgba(61, 71, 82, 0.2), 0 0 0 0 rgba(0, 127, 255, 0)",
                  },
                }}
                onClick={() =>
                  navigate(
                    isOnboardingComplete
                      ? "/job-application"
                      : "/career-onboarding"
                  )
                }
              >
                Take Control of Your Career with AI
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {spinner && <LoadingSpinner />}
    </LeftMenuLayout>
  );
};

export default OnBoardingHome;
