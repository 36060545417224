import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useData } from "../../contexts/DataContext";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  marginBottom: theme.spacing(1),
  "&.Mui-focused": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.5)",
  },
  color: "rgba(255, 255, 255, 0.9)",
  "&::placeholder": {
    color: "rgba(255, 255, 255, 0.5)",
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-checked": {
    color: "#A020F0",
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.9)",
  "&:hover": {
    color: "rgba(255, 255, 255, 1)",
  },
}));

export default function PersonalDevelopment() {
  const { updateFormData, formData } = useData();
  const initialData = formData?.form_info;

  const [formState, setFormState] = useState({
    newSkills: initialData?.newSkills || "",
    preferredLearningMethod: initialData?.preferredLearningMethod || "",
    personalInterestsAlignment: initialData?.personalInterestsAlignment || "",
    careerMotivation: initialData?.careerMotivation || "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    updateFormData(name, value);
  };

  return (
    <Box sx={{ color: "#ffffff", padding: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Personal Development
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="newSkills">
              What new skills would you like to learn?
            </StyledFormLabel>
            <StyledOutlinedInput
              id="newSkills"
              name="newSkills"
              value={formState.newSkills}
              onChange={handleChange}
              placeholder="Describe new skills"
              multiline
              rows={3}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel component="legend">
              Preferred learning method:
            </StyledFormLabel>
            <RadioGroup
              aria-label="preferredLearningMethod"
              name="preferredLearningMethod"
              value={formState.preferredLearningMethod}
              onChange={handleChange}
            >
              {[
                { value: "onlineCourses", label: "Online courses" },
                { value: "handsOn", label: "Hands-on" },
                { value: "mentorship", label: "Mentorship" },
                { value: "other", label: "Other" }
              ].map(
                (option) => (
                  <StyledFormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<StyledRadio />}
                    label={option.label}
                  />
                )
              )}
            </RadioGroup>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="personalInterestsAlignment">
              How do your personal interests align with your career goals?
            </StyledFormLabel>
            <StyledOutlinedInput
              id="personalInterestsAlignment"
              name="personalInterestsAlignment"
              value={formState.personalInterestsAlignment}
              onChange={handleChange}
              placeholder="Describe alignment of personal interests with career goals"
              multiline
              rows={3}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <StyledFormLabel htmlFor="careerMotivation">
              What motivates you in your career?
            </StyledFormLabel>
            <StyledOutlinedInput
              id="careerMotivation"
              name="careerMotivation"
              value={formState.careerMotivation}
              onChange={handleChange}
              placeholder="Describe career motivation"
              multiline
              rows={3}
            />
          </StyledFormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
