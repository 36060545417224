import React from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SuggestionCard = ({ onSuggestionClick, suggestions }) => {
  return (
    <>
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
          color: "#ffffff",
          fontSize: "16px",
          marginBottom: 2,
        }}
      >
        Suggestions
      </Typography>
      <Paper
        elevation={3}
        sx={{
          backgroundColor: "#1c1c1c",
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            minHeight: "fit-content",
            maxHeight: "400px",
            overflowY: "auto",
            scrollbarWidth: "none", // Hide scrollbar for Firefox
            msOverflowStyle: "none", // Hide scrollbar for IE and Edge
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for Chrome, Safari, and Opera
            },
          }}
        >
          {Object.entries(suggestions).map(([category, items]) => (
            <Accordion
              key={category}
              sx={{
                backgroundColor: "#2c2c2c",
                color: "#ffffff",
                "&:not(:last-child)": {
                  borderBottom: "1px solid #3c3c3c",
                },
                "&.Mui-expanded": {
                  margin: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#00B0FF" }} />}
                sx={{
                  backgroundColor: "#1c1c1c",
                  "&:hover": {
                    backgroundColor: "#333333",
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", fontSize: "14px" }}
                >
                  {category}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0, backgroundColor: "#2c2c2c" }}>
                <List sx={{ padding: 0 }}>
                  {items.map((item, index) => (
                    <ListItem
                      key={index}
                      button
                      onClick={() => onSuggestionClick(item)}
                      sx={{
                        borderBottom: "1px solid #3c3c3c",
                        "&:last-child": {
                          borderBottom: "none",
                        },
                        "&:hover": {
                          backgroundColor: "#444444",
                        },
                        transition: "background-color 0.2s",
                      }}
                    >
                      <ListItemText
                        primary={item}
                        primaryTypographyProps={{
                          sx: {
                            color: "rgb(255 255 255 / 63%)",
                            fontSize: "14px",
                            // transition: "color 0.2s",
                            "&:hover": {
                              // color: "#00B0FF",
                            },
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Paper>
    </>
  );
};

export default SuggestionCard;
