import React, { useState } from "react";
import { Box, Grid, Button, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormStepper from "./FormStepper";
import JobMatchForm from "./JobMatchForm";
import ConfirmationDialog from "./ConfirmationDialog";
import { useData } from "../../contexts/DataContext";
import { useNavigate } from "react-router-dom";
import Confirmation from "./Confirmation";
import LoadingSpinner from "../LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const steps = ["Job Match", "Confirmation"];

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  backdropFilter: "blur(10px)",
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  padding: theme.spacing(3),
  maxWidth: "1200px",
  width: "80%",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: "bold",
  color: "#ffffff",
  padding: "10px 20px",
  "&:disabled": {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

const StepperContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.07)",

  borderRadius: "10px",
  padding: theme.spacing(2),
  height: "100%",
}));

const FormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.07)",
  borderRadius: "10px",
  padding: theme.spacing(2),
  height: "100%",
}));

const JobApplicationFormLayout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const { submitFormData, formData, spinner, setSpinner } = useData();
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <JobMatchForm />;
      default:
        return (
          <Confirmation handleOpen={handleOpen} handleClose={handleClose} />
        );
    }
  };

  const handleConfirm = async () => {
    const infoToastId = toast.info("Submitting form data...", {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
    });
    setSpinner(true);
    setOpen(false);
    try {
      await submitFormData();
      toast.dismiss(infoToastId);
      toast.success("Form submitted successfully!", {
        onClose: () => {
          navigate("/job-match");
        },
      });
      setSpinner(false);
    } catch (error) {
      toast.dismiss(infoToastId);
      toast.error("Error submitting form");
      setSpinner(false);
      setOpen(false);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundSize: "400% 400%",
        animation: "backgroundAnimation 15s ease infinite",
        padding: { xs: 2, sm: 3, md: 4 },
      }}
    >
      {spinner && <LoadingSpinner />}
      <StyledPaper elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <StepperContainer>
              <FormStepper
                steps={steps}
                activeStep={activeStep}
                handleStep={handleStep}
              />
            </StepperContainer>
          </Grid>
          <Grid item xs={12} md={9}>
            <FormContainer>
              <Box
                sx={{
                  minHeight: "400px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>{getStepContent(activeStep)}</Box>
                {activeStep < steps.length - 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      pt: 2,
                      mt: 2,
                    }}
                  >
                    <StyledButton
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{
                        backgroundColor: "#3f51b5",
                        "&:hover": {
                          backgroundColor: "#303f9f",
                        },
                      }}
                    >
                      Previous
                    </StyledButton>

                    <Box sx={{ flex: "1 1 auto" }} />
                    {activeStep >= 2 && (
                      <StyledButton
                        variant="text"
                        onClick={handleSkip}
                        sx={{
                          color: "#ffffff",
                        }}
                      >
                        Skip
                      </StyledButton>
                    )}
                    <StyledButton
                      variant="contained"
                      onClick={handleNext}
                      sx={{
                        backgroundColor: "#A020F0",
                        "&:hover": {
                          backgroundColor: "navy",
                        },
                      }}
                    >
                      {activeStep === steps.length - 1 ? "Submit" : "Next"}
                    </StyledButton>
                  </Box>
                )}
              </Box>
            </FormContainer>
          </Grid>
        </Grid>
      </StyledPaper>

      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={1}
        enableMultiContainer
        containerId="jobApplicationForm"
      />
    </Box>
  );
};

export default JobApplicationFormLayout;
