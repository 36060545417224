import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useData } from "../../contexts/DataContext";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  marginBottom: theme.spacing(1),
  "&.Mui-focused": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-checked": {
    color: "#A020F0",
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.9)",
  "&:hover": {
    color: "rgba(255, 255, 255, 1)",
  },
}));

const stressFactorsOptions = [
  "Work deadlines",
  "Travel requirements",
  "Lack of sleep",
  "Family issues",
  "Financial concerns",
  "Health problems",
  "Workplace conflicts",
  "Commute",
  "Work-life balance",
  "Job insecurity",
  "Excessive workload",
  "Lack of career growth",
  "Personal relationships",
  "Technology issues",
  "Environmental factors",
];

export default function StressFactors() {
  const { updateFormData, formData } = useData();

  const initialData = formData?.form_info;
  const [formState, setFormState] = useState({
    mostStressFactors: initialData?.mostStressFactors || [],
    leastStressFactors: initialData?.leastStressFactors || [],
  });

  const handleStressFactorsChange = (factor, type) => {
    setFormState((prevState) => {
      const updatedFactors = prevState[type].includes(factor)
        ? prevState[type].filter((item) => item !== factor)
        : [...prevState[type], factor];

      updateFormData(type, updatedFactors);
      return { ...prevState, [type]: updatedFactors };
    });
  };

  const renderCheckboxes = (type) => (
    <Grid container spacing={2}>
      {stressFactorsOptions.map((factor) => (
        <Grid item xs={12} sm={6} md={4} key={factor}>
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                checked={formState[type].includes(factor)}
                onChange={() => handleStressFactorsChange(factor, type)}
                disabled={
                  formState[type].length >= 3 &&
                  !formState[type].includes(factor)
                }
              />
            }
            label={factor}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box sx={{ color: "#ffffff", padding: 3 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
        Stress Factors
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledFormControl component="fieldset" fullWidth>
            <StyledFormLabel component="legend">
              Select up to 3 factors that cause the MOST stress:
            </StyledFormLabel>
            {renderCheckboxes("mostStressFactors")}
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl component="fieldset" fullWidth>
            <StyledFormLabel component="legend">
              Select up to 3 factors that cause the LEAST stress:
            </StyledFormLabel>
            {renderCheckboxes("leastStressFactors")}
          </StyledFormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
