import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/system";
import {
  Person,
  Work,
  TrendingUp,
  Psychology,
  School,
  Description,
  CompareArrows,
  CheckCircle,
} from "@mui/icons-material";

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    display: "block",
    borderColor: "rgba(255, 255, 255, 0.3)",
    borderLeftStyle: "solid",
    borderLeftWidth: "2px",
    minHeight: "24px",
    marginLeft: "12px",
  },
}));

const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient(45deg, #A020F0, #2126f9)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient(45deg, #A020F0, #2126f9)",
  }),
}));

const CustomStepLabel = styled(StepLabel)(({ ownerState }) => ({
  [`& .MuiStepLabel-label`]: {
    color: "rgba(255, 255, 255, 0.7)",
  },
  [`& .MuiStepLabel-label.Mui-active`]: {
    color: "#ffffff",
    fontWeight: "bold",
  },
  [`& .MuiStepLabel-label.Mui-completed`]: {
    color: "#ffffff",
    fontWeight: "bold",
  },
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Person />,
    2: <Work />,
    3: <TrendingUp />,
    4: <Psychology />,
    5: <School />,
    6: <Description />,
    7: <CompareArrows />,
    8: <CheckCircle />,
  };

  return (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </CustomStepIconRoot>
  );
}

const FormStepper = ({ steps, activeStep, handleStep }) => {
  return (
    <Box>
      <Stepper
        orientation="vertical"
        activeStep={activeStep}
        connector={<CustomConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton color="inherit" onClick={() => handleStep(index)}>
              <CustomStepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon {...props} icon={index + 1} />
                )}
              >
                {label}
              </CustomStepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default FormStepper;
