import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ThemeProvider,
  createTheme,
} from "@mui/material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#A020F0",
    },
    background: {
      paper: "#1e1e1e",
    },
  },
});

const ConfirmationDialog = ({ open, handleClose }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#1e1e1e",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "#ffffff" }}>
          {"Confirm Submission"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#cccccc" }}
          >
            Are you sure you want to submit the form? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ConfirmationDialog;
