import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import { useData } from "../../contexts/DataContext";
import { styled } from "@mui/material/styles";
import LeftMenuLayout from "../Layout/LeftMenuLayout";
import BotBgAnimation from "../Animation/BotBgAnimation";
import LoadingSpinner from "../LoadingSpinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  backdropFilter: "blur(10px)",
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  marginTop: theme.spacing(3),
  maxHeight: 400,
  overflow: "auto",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#ffffffba",
  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
  padding: theme.spacing(2),
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: "#ffffff",
  borderBottom: "2px solid rgba(255, 255, 255, 0.2)",
  padding: theme.spacing(2),
  fontWeight: "bold",
  backgroundColor: "rgba(30, 30, 30, 0.95)",
  backdropFilter: "blur(10px)",
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  backdropFilter: "blur(10px)",
  borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  padding: theme.spacing(3),
  maxWidth: "1200px",
  width: "60%",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#ffffff",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&:disabled": {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#A020F0",
    },
    background: {
      paper: "#1e1e1e",
    },
  },
});

const FileManagement = () => {
  const {
    spinner,
    fileManagementUpload,
    handleDeleteFile,
    handleDownloadFile,
    setSpinner,
    formData,
  } = useData();
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [activeCategory, setActiveCategory] = useState("resume");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const categories = [
    {
      id: "resume",
      name: "Resume",
    },
    {
      id: "job_description",
      name: "Job Description",
    },
    {
      id: "aspirant_profile",
      name: "Aspirant Profile",
    },
    {
      id: "user_files",
      name: "User Files",
    },
  ];

  const handleFileUpload = async (event, category) => {
    const infoToastId = toast.info(`Uploading file...`, {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
    });
    setSpinner(true);
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      try {
        const response = await fileManagementUpload(files, category);
        if (response.success) {
          toast.dismiss(infoToastId);
          toast.success(`File uploaded successfully!`);
        } else {
          toast.dismiss(infoToastId);
          toast.error(`Failed to upload file.`);
        }
      } catch (error) {
        toast.dismiss(infoToastId);
        toast.error(`An error occurred while uploading file.`);
      }
    }
    setSpinner(false);
    setFileInputKey(Date.now());
  };

  const handleDeleteClick = (fileId, category, file_name, file_url) => {
    setSelectedFiles([{ file_id: fileId, category, file_name, file_url }]);
    setDeleteConfirmOpen(true);
  };
  const handleDeleteConfirm = async () => {
    if (selectedFiles.length > 0) {
      const infoToastId = toast.info(`Deleting files...`, {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
      });
      setSpinner(true);
      setDeleteConfirmOpen(false);
      try {
        const response = await handleDeleteFile(selectedFiles);
        if (response.status === 200) {
          toast.dismiss(infoToastId);
          toast.success(`Files deleted successfully!`);
        } else {
          toast.dismiss(infoToastId);
          toast.error(`Failed to delete files.`);
        }
      } catch (error) {
        toast.dismiss(infoToastId);
        toast.error(`An error occurred while deleting files.`);
      }
      setSpinner(false);
    }
    setSelectedFiles([]);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setSelectedFiles([]);
  };

  const handleDownload = async (fileUrl) => {
    const infoToastId = toast.info(`Downloading file...`, {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
    });
    setSpinner(true);
    try {
      await handleDownloadFile(fileUrl);
      toast.dismiss(infoToastId);
      toast.success(`File download initiated successfully!`);
    } catch (error) {
      toast.dismiss(infoToastId);
      toast.error(`An error occurred while downloading file.`);
    }
    setSpinner(false);
  };

  const getFileType = (fileName) => {
    const extension = fileName?.split(".").pop().toUpperCase();
    return extension;
  };

  const renderFileTable = (category) => {
    const categoryFiles = formData?.form_info?.files?.filter(
      (file) => file.category === category
    );
    return (
      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>File Name</StyledTableHeaderCell>
              <StyledTableHeaderCell align="right">Size</StyledTableHeaderCell>
              <StyledTableHeaderCell align="right">Type</StyledTableHeaderCell>
              <StyledTableHeaderCell align="right">
                Actions
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoryFiles?.map((file) => (
              <StyledTableRow key={file.file_id}>
                <StyledTableCell component="th" scope="row">
                  {file.file_name}
                </StyledTableCell>
                <StyledTableCell align="right">{"250 KB"}</StyledTableCell>
                <StyledTableCell align="right">
                  <Chip
                    label={getFileType(file.file_name)}
                    size="small"
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "#ffffffba",
                      fontWeight: "bold",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        handleDeleteClick(
                          file.file_id,
                          category,
                          file.file_name,
                          file.file_url
                        )
                      }
                    >
                      <DeleteIcon sx={{ color: "error.main" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download">
                    <IconButton
                      aria-label="download"
                      onClick={() => handleDownload(file.file_url)}
                    >
                      <DownloadIcon sx={{ color: "success.main" }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  };

  return (
    <LeftMenuLayout>
      <BotBgAnimation />
      <Box
        sx={{
          minHeight: "100vh",
          backgroundSize: "400% 400%",
          animation: "backgroundAnimation 15s ease infinite",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ color: "#ffffff", mb: 4 }}>
          File Management
        </Typography>
        <StyledPaper elevation={3} sx={{ width: "80%", maxWidth: "1200px" }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: "#ffffff", mb: 3 }}
          >
            Categories
          </Typography>
          <Box sx={{ display: "flex", mb: 3, width: "100%" }}>
            {categories?.map((category) => (
              <Chip
                key={category.id}
                label={category.name}
                onClick={() => setActiveCategory(category.id)}
                sx={{
                  flex: 1,
                  mr: 1,
                  backgroundColor:
                    activeCategory === category.id
                      ? "primary.main"
                      : "rgba(255, 255, 255, 0.08)",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              />
            ))}
          </Box>
          <StyledButton
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
            sx={{ mb: 3 }}
          >
            Upload {activeCategory}
            <input
              type="file"
              hidden
              onChange={(e) => handleFileUpload(e, activeCategory)}
              accept=".pdf,.doc,.docx"
              key={fileInputKey}
              multiple
            />
          </StyledButton>
          {renderFileTable(activeCategory)}
          {formData?.form_info?.files?.filter(
            (file) => file.category === activeCategory
          ).length === 0 && (
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", mt: 2, fontStyle: "italic" }}
            >
              No {activeCategory} files uploaded yet. Start by uploading a file.
            </Typography>
          )}
        </StyledPaper>
        {spinner && <LoadingSpinner />}
      </Box>
      <ThemeProvider theme={darkTheme}>
        <Dialog
          open={deleteConfirmOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: "#1e1e1e",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title" sx={{ color: "#ffffff" }}>
            {"Confirm Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "#cccccc" }}
            >
              Are you sure you want to delete{" "}
              {selectedFiles.length === 1 ? "this file" : "these files"}? This
              action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </LeftMenuLayout>
  );
};

export default FileManagement;
