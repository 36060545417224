import React, { useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ReactComponent as GoogleIcon } from '../../assets/google-icon-logo.svg';
import MicrosoftLogo from '../../assets/MSFT-a203b22d.png'; // Adjust the path as needed
import { useNavigate } from "react-router-dom";
import { useData } from "../../contexts/DataContext";
import PocketBase from 'pocketbase';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.7)",
  marginBottom: theme.spacing(1),
  "&.Mui-focused": {
    color: "rgba(255, 255, 255, 0.7)",
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  margin: '16px 0',
  '&::before, &::after': {
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
}));


const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.23)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.5)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(255, 255, 255, 0.5)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "rgba(255, 255, 255, 0.7)",
  },
  "& .MuiInputBase-input": {
    color: "rgba(255, 255, 255, 0.9)",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: "bold",
  color: "#ffffff",
  padding: "10px 20px",
  "&:disabled": {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: "#070B14",
  backdropFilter: "blur(10px)",
  // borderRadius: "15px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  border: "1px solid rgba(255, 255, 255, 0.1)",
}));

const StyledOAuthButton = styled(Button)(({ theme }) => ({
  fontWeight: "bold",
  color: "#757575",
  padding: "10px 20px",
  backgroundColor: "#ffffff",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const StyledGoogleIcon = styled(GoogleIcon)({
  width: '20px',
  height: '20px',
  marginRight: '8px',
});

const StyledMicrosoftLogo = styled('img')({
  width: '20px',
  height: '20px',
  marginRight: '8px',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

const StyledLockIcon = styled(LockOutlinedIcon)(({ theme }) => ({
  fontSize: theme.spacing(4),
}));

const LogInSignUpForm = () => {
  const { setGlobalEmail, setGlobalAccessToken } = useData();
  const [logIn, setLogIn] = useState(true);
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (logIn) {
      const infoToastId = toast.info("Logging in...", {
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
      });
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: formState.email,
            password: formState.password,
          }),
        });

        const data = await response.json();
        if (response.ok) {
          localStorage.setItem('AccessToken', data.token);
          localStorage.setItem('email', formState.email);
          setGlobalEmail(formState.email);
          setGlobalAccessToken(data.token);
          toast.dismiss(infoToastId);
          toast.success("Login Successful!");
          navigate("/onboarding-home");
        } else {
          toast.dismiss(infoToastId);
          toast.error(data.message || "Invalid email or password.");
        }
      } catch (error) {
        toast.dismiss(infoToastId);
        toast.error("An error occurred during login.");
      }
    } else {
      // Sign up logic
      if (formState.email && formState.password && formState.firstName && formState.lastName) {
        const infoToastId = toast.info("Signing up...", {
          autoClose: false,
          closeOnClick: false,
          closeButton: false,
        });
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              first_name: formState.firstName,
              last_name: formState.lastName,
              email: formState.email,
              password: formState.password,
            }),
          });

          const data = await response.json();
          if (response.ok) {
            toast.dismiss(infoToastId);
            toast.success("Sign Up Successful!");
            setLogIn(true)
          } else {
            toast.dismiss(infoToastId);
            toast.error(data.message || "Sign Up failed. Please try again.");
          }
        } catch (error) {
          toast.dismiss(infoToastId);
          toast.error("An error occurred during sign up.");
        }
      } else {
        toast.error("Please fill in all fields.");
      }
    }
  };

  const toggleLogin = () => {
    setLogIn(!logIn);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGoogleAuth = async () => {
    const infoToastId = toast.info("Authenticating with Google...", {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
    });
    try {
      const authData = await pb.collection('users').authWithOAuth2({ provider: 'google' });
      if (authData) {
        const token = authData.token;
        const email = authData.meta.email;
        
        localStorage.setItem("AccessToken", token);
        localStorage.setItem("email", email);
        
        setGlobalEmail(email);
        setGlobalAccessToken(token);

        // Check subscription status
        const response = await fetch(
          `https://coachdemo.hrbrain.ai/api/check-subscription`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email }),
          }
        );
        toast.dismiss(infoToastId);
        toast.info("Checking subscription status...");
        const subscriptionData = await response.json();
        if (subscriptionData.has_active_subscription) {
          toast.success("Subscription status checked successfully");
          navigate("/onboarding-home", { replace: true });
        } else {
          window.location.href = process.env.REACT_APP_STRIPE_CHECKOUT_URL;
        }
      }
    } catch (error) {
      toast.dismiss(infoToastId);
      toast.error("An error occurred during Google authentication.");
    }
  };

  const handleMicrosoftAuth = async () => {
    
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url("https://www.simplilearn.com/ice9/free_resources_article_thumb/ai_in_iot.jpg")',
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={StyledPaper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <StyledAvatar>
            <StyledLockIcon />
          </StyledAvatar>
          <Typography component="h5" variant="h5" sx={{ color: "white", fontWeight: "bold", mb: 1 }}>
            {logIn ? "Sign In" : "Sign Up"}
          </Typography>
          {logIn ? (
            <>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={formState.email}
                  onChange={handleChange}
                />
                <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={formState.password}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                  sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                />
                <StyledButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#A020F0",
                    "&:hover": {
                      backgroundColor: "#388e3c",
                    },
                  }}
                >
                  Sign In
                </StyledButton>
              </Box>
              <StyledDivider>
                <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)", px: 2, backgroundColor: 'inherit' }}>
                  OR
                </Typography>
              </StyledDivider>
              <StyledOAuthButton
                fullWidth
                variant="contained"
                startIcon={<StyledGoogleIcon />}
                onClick={handleGoogleAuth}
                sx={{ mt: 1, mb: 2 }}
              >
                Sign in with Google
              </StyledOAuthButton>
              {/* <StyledOAuthButton
                fullWidth
                variant="contained"
                startIcon={<StyledMicrosoftLogo src={MicrosoftLogo} alt="Microsoft logo" />}
                sx={{ mt: 1, mb: 2 }}
              >
                Sign in with Microsoft
              </StyledOAuthButton> */}
            </>
          ) : (
            <>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <StyledTextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      value={formState.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      value={formState.lastName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={formState.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="new-password"
                      value={formState.password}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <StyledButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#A020F0",
                    "&:hover": {
                      backgroundColor: "#388e3c",
                    },
                  }}
                >
                  Sign Up
                </StyledButton>
              </Box>
              <StyledDivider>
                <Typography variant="body2" sx={{ color: "rgba(255, 255, 255, 0.7)", px: 2, backgroundColor: 'inherit' }}>
                  OR
                </Typography>
              </StyledDivider>
              <StyledOAuthButton
                fullWidth
                variant="contained"
                startIcon={<StyledGoogleIcon />}
                onClick={handleGoogleAuth}
                sx={{ mt: 1, mb: 2 }}
              >
                Sign up with Google
              </StyledOAuthButton>
              {/* <StyledOAuthButton
                fullWidth
                variant="contained"
                startIcon={<StyledMicrosoftLogo src={MicrosoftLogo} alt="Microsoft logo" />}
                sx={{ mt: 1, mb: 2 }}
              >
                Sign up with Microsoft
              </StyledOAuthButton> */}
            </>
          )}
          <Grid container>
            <Grid item xs>
              <Button variant="text" sx={{ color: "rgba(255, 255, 255, 0.7)", textTransform: "none" }}>
                Forgot password?
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={toggleLogin} variant="text" sx={{ color: "rgba(255, 255, 255, 0.7)", textTransform: "none" }}>
                {logIn
                  ? "Don't have an account? Sign up"
                  : "Already have an account? Sign in"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Grid>
  );
};

export default LogInSignUpForm;